import baseURL from "@/utils/config";
import request from "@/plugins/axios"
//获取所有明细
export function getCheckList(data){
  return request({
      url:`${baseURL.api}/authapi/user/findRechargeRecord`,
      method: "post",
      data,
  });
}
//获取用户余额
export function getBalance(){
  return request({
      url:`${baseURL.api}/authapi/user/findRecharge`,
      method: "post",
  });
}
//按照月份分类
export function getCheckDetial(data){
  return request({
      url:`${baseURL.api}/authapi/user/findRechargeRecord2`,
      method: "post",
      data,

  });
}

export function getUserInfo(data){
  return request({
      url:`${baseURL.api}/authapi/user/userinfo2`,
      method: "post",
      data,
  });
}
